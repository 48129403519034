import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid2,
  FormControl,
  Input,
} from "@mui/material";
import ContinueButton from "../Components/ContinueButton";
import Header from "../Layout/Header";
import { getCookie } from "../Utils/cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, Toaster } from "sonner";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getData = getCookie("data") || {};

  const validatePassword = () => {
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long.");
      return false;
    }

    // Additional validation logic (e.g., special characters, numbers) can go here
    setErrorMessage(null);
    return true;
  };

  const handleSubmit = async () => {
    if (!validatePassword()) {
      return;
    }

    try {
      setIsLoading(true);

      // Extract the 'token' query parameter
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");

      const reqBody = {
        password,
        token
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}auth/resetPassword`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(reqBody),
        }
      );

      const updatedPassword = await response.json();

      if (updatedPassword?.status === 200) {
        toast.success("Password Updated Successfully.");
        setTimeout(() => navigate("/thank-you"), 1500);
      } else {
        const errorMessage = updatedPassword?.data || "An unknown error occurred.";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setErrorMessage("An error occurred while resetting the password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Box
        className="main-div-animate"
        sx={{
          height: 'calc(100vh - 115px)',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 2,
        }}
      >
        <div>
          <Typography
            variant="h4"
            className="text-color"
            sx={{
              fontWeight: "900",
              marginBottom: "20px",
              textAlign: "center",
              fontSize: { xs: "20px", sm: "30px" },
              width: { xs: "68vw", md: "450px" },
            }}
          >
            Please Enter New Password
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                mt: 3,
                width: { xs: "80%", sm: "40%", md: "30%" },
                minWidth: '280px'
              }}
            >
              <Input
                id="password"
                disableUnderline
                className="text-color"
                sx={{
                  borderBottom: getData.gender === "female"
                    ? "1px solid #ffbfe5"
                    : "1px solid white",
                  textAlign: "center",
                }}
                placeholder="Enter your new password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorMessage(null);
                }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
              />
              {errorMessage && (
                <Typography
                  sx={{ textAlign: "center", marginTop: "5px", color: "red" }}
                  fontSize="14px"
                >
                  {errorMessage}
                </Typography>
              )}
            </FormControl>
          </Box>
        </div>

        <Grid2
          className="background-color-secondary"
          sx={{
            display: 'flex',
            borderTop: getData?.gender === "female" ? "1px solid #cccccc" : "",
            marginTop: "auto",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            position: "absolute",
            bottom: 0,
          }}
        >
          <ContinueButton
            isLoading={isLoading}
            updateDataInLocalStorage={handleSubmit}
          />
        </Grid2>
      </Box>

      <Toaster richColors />
    </>
  );
};

export default ResetPassword;
