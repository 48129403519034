import { Box } from "@mui/material";

// import Loader from "../assets/Images/loader.gif";

const LoaderGif = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <img
        src={`${process.env.REACT_APP_PUBLIC_URL_IMAGE_SVG}/loader.gif`}
        alt="Loading..."
        style={{ width: "150px" }}
      />
    </Box>
  );
};

export default LoaderGif;
