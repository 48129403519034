import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";

const dummyMoneyBack = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Money-Back Guarantee - Fitness Planner</title>
</head>
<body>
    <h1>Money-Back Guarantee</h1>

    <h2>I. MONEY-BACK GUARANTEE RULES</h2>
    <p>
        We stand behind the quality of our program and offer refunds if you can demonstrate that you followed the program as prescribed and did not see visible results. To qualify for a refund, you must meet the following conditions:
    </p>
    <ol>
        <li>
            <strong>Submit a Refund Request Before Renewal:</strong>
            Contact us before your subscription period renews.
        </li>
        <li>
            <strong>Program Adherence:</strong>
            <ul>
                <li>For monthly and longer subscription periods: Follow the program for at least 10 days within the first 30 days after purchase.</li>
                <li>For weekly subscription periods: Follow the program for at least 4 consecutive days within the first 7 days after purchase.</li>
            </ul>
        </li>
        <li>
            <strong>Provide Proof of Program Completion:</strong>
            Submit screenshots or other app data showing that you completed the required workout sessions as outlined above.
        </li>
        <li>
            <strong>Refund Request Review:</strong>
            Once your request is submitted, we will review your proof of adherence and notify you via email or another method regarding whether your refund request has been approved.
        </li>
    </ol>

    <h2>IMPORTANT STATEMENT</h2>
    <p>
        Refunds will only be issued if all the above criteria are fully met. This Money-back Guarantee is strictly limited to the conditions outlined and does not apply to other circumstances or situations.
    </p>
</body>
</html>
`;

const MoneyBackGuarantee = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}cms/money-back-guarantee`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();
      setLoading(false);
      if (data.status === 200) {
        // console.log(decodeURIComponent(data.data?.cmsPage?.content));
        setData(decodeURIComponent(data.data?.cmsPage?.content));
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box sx={{ backgroundColor: "#fff", minHeight: "100vh", height: "100%" }}>
      <Header />
      {!loading && (
        <Box
          sx={{ px: 2 }}
          dangerouslySetInnerHTML={{
            __html: data ? data : dummyMoneyBack,
          }}
        />
      )}
    </Box>
  );
};

export default MoneyBackGuarantee;
