import { useState } from "react";
import { toast } from 'sonner'
import { getCookie } from "../Utils/cookie";

const usePayment = () => {
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [paymentError, setPaymentError] = useState(null);
    const [subscription, setSubscription] = useState(null);

    const createStripeSubscription = async ({ isRecurring, paymentMethodId, planId, userId }) => {
        const { token } = getCookie("data");
        setPaymentLoading(true);
        setPaymentError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASEURL}webSubscription/createStripeSubscription`, {
                method: "POST",
                headers: {
                    "authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    isRecurring,
                    paymentMethodId,
                    planId
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.msg || "Failed to create subscription. Please try again.");
            }

            const result = await response.json();
            setSubscription(result);
            // console.log("createStripeSubscription", result)
            return result;
        } catch (err) {
            console.error("Subscription error:", err);
            toast.error(err?.message)
            setPaymentError(err.message);
            setPaymentLoading(false)
            return null;
        }
    };

    return {
        createStripeSubscription,
        subscription,
        paymentLoading,
        setPaymentLoading,
        paymentError,
    };
};

export default usePayment;
