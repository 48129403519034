import React, { useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardPaymentView from "./CardPaymentView";
import { Box, Stack } from "@mui/material";

const StripeMainView = ({ goToNextSlide, currentPlan }) => {
  const key = process.env.REACT_APP__STRIPE_CLIENT_KEY || "";
  const stripePromise = loadStripe(key);

  // Card logo image URLs
  const cardLogos = [
    `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/visa_logo_lg.png`,
    `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/master_logo_big.png`,
    `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/mestro_logo_big.png`,
    `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/discover_logo_big.png`,
    `${process.env.REACT_APP_PUBLIC_URL_IMAGE_PNG}/amex_logo_big.png`
  ];

  useEffect(() => {
    // Preload images
    cardLogos.forEach(logoUrl => {
      const img = new Image();
      img.src = logoUrl;
    });
  }, []); // Empty dependency array means this runs once on component mount

  return (
    <Box>
      <Stack
        mt={1}
        direction={"row"}
        width={"100%"}
        justifyContent={"center"}
        flexWrap={"wrap"}
      >
        {cardLogos.map((logoUrl, index) => (
          <img
            key={index}
            alt={['visa', 'master', 'mestro', 'discover', 'amex'][index]}
            src={logoUrl}
          />
        ))}
      </Stack>
      <Elements stripe={stripePromise}>
        <CardPaymentView
          goToNextSlide={goToNextSlide}
          currentPlan={currentPlan}
        />
      </Elements>
    </Box>
  );
};

export default StripeMainView;