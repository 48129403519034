import { Button, CircularProgress } from "@mui/material";
import React from "react";

const ContinueButton = ({ isLoading, disabled = false, updateDataInLocalStorage, text }) => {
  return (
    <Button
      className="button"
      variant="contained"
      sx={{
        // backgroundColor: "#1b6ef3",
        color: "white",
        padding: "10px 60px",
        fontSize: "17px",
        borderRadius: "10px",
        boxShadow: "none",
        fontWeight: "600",
        maxWidth: "335px",
        width: "100%",
        "&:hover": {
          backgroundColor: "#185ad9",
        },
      }}
      onClick={() => {
        updateDataInLocalStorage();
      }}
      disabled={disabled}
    >
      {
        isLoading ? (<CircularProgress color="inherit" size="25px" />) : text ? text : "CONTINUE"
      }

    </Button>
  );
};

export default ContinueButton;
